div#surveyNavigation {
    display: block;

    &.fixed-bottom {
        // top: calc(100vh - 100px);
        bottom: 30px;
        position: fixed;
        right: 0;
        left: 0;
        z-index: 1100;
    }

    .inner {
        -webkit-box-shadow: 0px 1px 5px 1px #aaa;
        box-shadow: 0px 1px 5px 1px #aaa;
        background-color: #ffffff;
        padding: 5px;
        // height: 100px;

        .buttons {
            background-color: #ffffff;
        }
    }

    .btn-style {
        border-radius: 100px;
    }

    .disabled {
        background-color: #CCCCCD; 
        color: #8A898B;
    }
}

@media (max-width: 767px) {
    div#surveyNavigation.fixed-bottom {
        bottom: 86px;
    }
}

@media (max-width: 900px) {
    div#surveyNavigation.fixed-bottom {
        bottom: 50px;
    }
}
